.swdevheadmaincontainer {
    width: 65vw;
    margin: auto;
    display: flex;
    gap: 5vw;
    margin-top: 5vh;
    flex-wrap: wrap;
}

.swdevlistheader {
    font-size: 1.2rem;
    color: #0eaae3;
    font-weight: 600;
}

.swdevheadlist {
    margin: 0vh 0vw;
}

.swdevheadlist ul {
    margin-top: 2vh;
}

.swdevheadlist ul li {
    color: #595959;
    margin-left: 1.5vw;
}

.dataaicard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    border: 1px solid rgb(195, 195, 195);
    padding: 10px;
}

.dataaicardimage {
    width: 100%;
    height: 250px;
    /* height: 45%; */
}

.dataaicardimage img {
    width: 100%;
    height: 100%;
}

.dataaicardinfo {
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
    gap: 2vh;
}

.dataaicardinfo label {
    font-size: 2rem;
    font-weight: 600;
    color: #595959;
}

.dataaicardinfo p {
    margin-top: 3vh;
    font-size: 1.3rem;
    color: #7f7f7f
}

.dataaiexplorebtn {
    margin-top: 3vh;
    width: 100%;
}

.dataaibtncontainer {
    display: flex;
    align-items: center;
    gap: 1vw;
    color: #0eaae3;
    font-size: 1.3rem;
    font-weight: 500;
    cursor: pointer;
}

.dataaibtncontainer i {
    font-size: 1.3rem;

    padding-top: 5px;
}

.allengsermaincontainer {
    width: 100vw;
    margin: 8vh 0;
}

.allengserchildcontainer {
    width: 65vw;
    margin: auto;
}

.allengsercardsheader {
    font-size: 2rem;
    /* font-weight: 600; */
    color: #0eaae3;
    /* text-align: center; */
}

.allengsercards {
    margin-top: 5vh;
    display: flex;
    justify-content: space-between;
}

.allengsercard {
    width: 32%;
    border-radius: 20px;
    text-decoration: none;
    background-color: #3e3e3e;
}

.allengsercard:hover {
    background-color: #4e4e4e;
    transition: .3s;
}

.allengsercarddiv1 {
    display: flex;
    align-items: center;
    padding: 50px 50px;
    gap: 10px;
    color: white;
    /* justify-content: space-evenly; */
}

.allengsercardimg i {
    font-size: 3rem;
}

.allengsercardheading {
    font-size: 1.7rem;
    font-weight: 600;
}

.allengsercarddiv2 {
    padding: 0px 40px;
    font-size: 1.6rem;
    color: white;
    padding-bottom: 40px;
}

@media only screen and (max-width: 600px) {
    .allengserchildcontainer{
        width: 80vw;
    }

    .allengsercards{
        margin-top: 3vh;
        flex-direction: column;
        gap: 2vh;
    }
    
    .allengsercard{
        width: 100%;
    }

    .allengsercardimg i{
        font-size: 1.5rem;
    }

    .allengsercardheading{
        font-size: 1.1rem;
    }

    .allengsercarddiv1{
        padding: 0 30px;
        padding-top: 30px;
    }
    
    .allengsercarddiv2{
        padding: 30px 30px;
        font-size: 1.1rem;
    }
}