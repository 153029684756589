.errorbgimage{
    position: relative;
}

.errorbgimage img{
    position: absolute;
    right: 10vw;
    top: -10vh;
}

.errorpagemaincontainer{
    width: 100%;
    height: 100vh;
    background-color: #0E2854;
    position: relative;
}

.errorpagecontent{
    position: absolute;
    flex-direction: column;
    gap: 10vh;
    top: 0;
    height: 100vh;
    width: 55vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorpageinfo{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.errorpageheader1{
    font-size: 2rem;
    font-weight: 600;
    color: #68CAE5;
}

.errorpageheader2{
    margin-top: 1vh;
    font-size: 2rem;
    font-weight: 600;
    color: #68CAE5;
}

.errorpageheader3{
    color: white;
    font-size: 15rem;
    font-weight: 600;
    display: flex;
    align-items: start;
}

.errorpageheader4{
    font-weight: 600;
    font-size: 4rem;
    color: #68CAE5;
}

.errorpagebtn{
    padding: 1.5vh 2vw;
    font-size: 1.5rem;
    cursor: pointer;
    display: flex;
    gap: 1vw;
    align-items: center;
    background-color: #1db7e2;
    color: white;
    border-radius: 40px;
    transition: .3s;
}

.errorpagebtn:hover{
    background-color: #00a4d1;
}

@media only screen and (max-width : 800px) {
    .errorbgimage{
        width: 25vw;
        height: 30vh;
        position: absolute;
        right: 2px;
    }

    .errorpagecontent{
        width: 100vw;
        height: auto;
        gap: 0;
        top: auto;
    }

    .errorpagecontentparent{
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .errorpageheader3{
        font-size: 8rem;
    }

    .errorpageheader4{
        font-size: 2rem;
    }

    .errorpageinfo{
        margin-bottom: 4vh;
    }

    .errorpagebtn{
        padding: 10px 25px;
    }

    .errorbgimage img{
        position:static;
        width: 100%;
        height: 100%;
    }
}