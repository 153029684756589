.careerinfomaincontainer {
    width: 100vw;
    margin: 8vh 0;
}

.careerinfochildcontainer {
    width: 65vw;
    margin: auto;
}

.careerinfodiv1 {
    margin-bottom: 4vh;
}

.careerinfoheader {
    font-size: 2rem;
    /* font-weight: 600; */
    color: #0eaae3;
}

.careerinfodesc {
    margin-top: 4vh;
    font-size: 1.6rem;
    color: rgb(117, 117, 117);
}

.careerinfodesc ul {
    margin-left: 30px;
}

.careerinfodesc ul li { margin-bottom: 10px;}

.careerinfodesc ul li::marker {
    color: #0eaae3;
}

