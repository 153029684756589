.dataenginfomaincontainer {
    width: 100vw;
    /* background-color: #3E3E3E; */
    margin: 8vh 0;
    /* padding: 4vh 0; */
}

.dataenginfochildcontainer {
    width: 65vw;
    margin: auto;
}

.dataenginfomainheader {
    font-size: 2rem;
    color: #0eaae3;
    /* font-weight: 600; */
}

.dataenginfodiv2 {
    margin-top: 6vh;
    display: flex;
    justify-content: center;
    width: 100%;
}

.dataenginfodiv2 img {
    width: 100%;
}

.dataenginfomainpic {
    width: 100%;
    margin-bottom: 8vh;
}

.dataenginfomaindesc {
    margin: 1vh 0;
    margin-bottom: 4vh;
    font-size: 1.6rem;
    color: rgb(117, 117, 117);
}

.dataenginfomainsubhead {
    font-size: 1.6rem;
    /* font-weight: 600; */
    color: rgb(117, 117, 117);
    margin: 2vh 0;
    margin-bottom: 1vh;
}

.dataengherolistdesc{
    color: rgb(117, 117, 117);
}

.dataengherolist{
    margin-top: 1vh;
}

@media only screen and (max-width: 600px) {
    .dataenginfomainheader {
        font-size: 1.4rem;
    }

    .dataenginfomaindesc {
        font-size: 1.1rem;
    }

    .dataenginfochildcontainer {
        width: 80vw;
    }

    .dataenginfodiv2 {
        margin-top: 2vh;
    }

    .dataenginfomainsubhead {
        font-size: 1.1rem;
    }

    .dataenginfomaindesc {
        margin-bottom: 0;
    }

    .accordion li label::before{
        font-size: 2rem;
    }

    .accordion li label {
        font-size: 1.1rem;
    }
}