.trendingtechmaincontainer {
    width: 100vw;
    margin: 8vh 0;
}

.trendingtechchildcontainer {
    width: 65vw;
    margin: auto;
}

.trendingtechmainheader {
    font-size: 2rem;
    color: #0eaae3;
    /* font-weight: 600; */
}

.trendingtechdiv2 {
    margin-top: 8vh;
    display: flex;
    width: 100%;
    gap: 3vw;
    justify-content: center;
}

.trendingtechdiv2 img {
    width: 40%;
}

@media only screen and (max-width: 600px) {
    .trendingtechdiv2 {
        flex-direction: column;
        margin-top: 3vh;
    }

    .trendingtechdiv2 img {
        width: 100%;
    }
}