.aboutheromaincontainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.aboutheroheader p {
    padding-left: 4vw;
    border-left: 2px solid #0eaae3;
}

.aboutheroheader {
    background-color: transparent;
    background-image: linear-gradient(180deg, #e1f5fe 60%, #ffffff 85%);
    color: #0eaae3;
    padding-top: 20vh;
    font-size: 3rem;
    width: 100%;
}

.aboutherosection1 {
    width: 45%;
    display: flex;
    flex-direction: column;
    /* position: relative;
    top: -10px; */
    /* gap: 8vh; */
    justify-content: space-evenly;
    padding: 0vh 4vw;
}

.aboutherosection1infoheader {
    font-size: 2rem;
}

.aboutherosection1infoheader p span {
    color: #0eaae3;
    font-weight: 600;
}

.aboutherosection1infodescription {
    /* margin-top: 4vh; */
    font-size: 1.35rem;
    color: rgb(117, 117, 117);
    text-wrap: wrap;
    word-spacing: .4cap;
}

.ahs1info {
    /* margin-left: -2.5vw; */
    position: relative;
    left: -1vw;
    display: flex;
    flex-direction: column;
    gap: 4vh;
}

.ahs1people {
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.ahs1peopleheader {
    /* display: flex;
    gap: ; */
    font-size: 2.5rem;
    color: #0eaae3;
}

.ahs1peopledescription {
    font-size: 1.3rem;
    color: #595959;
}

.aboutherosection2 {
    width: 35vw;
    padding-bottom: 5vh;
    height: 65vh;
    display: flex;
    mask-size: 100% 100%;
    -webkit-mask-repeat: no-repeat;
}


.aboutherosection2 img {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width : 600px) {
    .aboutherosection1infodescription {
        margin-top: 0;
    }

    
}