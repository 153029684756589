.dtsheromaincontainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    align-items: center;
    /* height: 50vh; */
    /* position: relative;
    top: -50px; */
}

.aboutheroheader{
    /* background-color: transparent;
    background-image: linear-gradient(180deg, #e1f5fe 72%, #ffffff 85%); */
    /* padding-left: 0vh; */
}

.aboutherosection1infodescription ul li::marker {
    color: #0eaae3;
}

.aboutherosection1infodescription i{
    color: #0eaae3;
    text-align: center;
    font-size: .5rem;
}

@media only screen and (max-width : 480px) {
    .dtsheromaincontainer {
        flex-direction: column-reverse;
    }
    
    
}