.sapbtpformsection {
    width: 65vw;
    /* height: 87vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* background-color: antiquewhite; */
}

.sapbtpinfosection {
    width: 30vw;
    /* height: 87vh; */
    display: flex;
    padding: 2vh 1vw;
    flex-direction: column;
    gap: 3vh;
    background-color: #022A5E;
}

.sapbtpappointmentmaincontainer {
    margin-top: 13vh;
    display: flex;
}

.sapbtpmainheader {
    font-size: 2.2rem;
    color: #0eaae3;
    font-weight: 600;
    display: flex;
}

.sapbtpsubheader {
    font-size: 1.2rem;
    font-weight: 600;
    color: #0eaae3;
}

.sapbtpdescription {
    color: white;
    font-size: .9rem;
}

.sapbtpdescription ul {
    margin-left: 1vw;
    color: white;
}

.sapbtpformheader {
    font-size: 2rem;
    padding-bottom: 3vh;
    color: #0eaae3;
}

.sapbtpformbody {
    width: 65vw;
    display: flex;
    justify-content: space-between;
    /* padding: 0vh 4vw; */
    flex-wrap: wrap;
}

.sapbtpinputbox {
    width: 45%;
    display: flex;
    gap: 1vh;
    flex-direction: column;
    /* margin-bottom: 3vh; */
}

.formerrormessage{
    visibility: hidden;
    color: red;
}

.sapbtpinputbox label {
    color: rgb(117, 117, 117);
    font-size: 1.6rem;
}

.sapbtpinputbox label span {
    color: #0eaae3;
}

.sapbtpinputbox input {
    background-color: #f2f2f2;
    color: rgb(53, 53, 53);
    outline: none;
    border: 0;
    padding: 18px 15px;
    font-size: 1.3rem;
}

/* .sapbtpinputbox input: {} */

.sapbtpinputbox input::-webkit-file-upload-button {
    content: "Max 5MB";
    font-size: 1rem;
    outline: none;
}

.sapbtpinputbox textarea {
    background-color: #f2f2f2;
    color: rgb(53, 53, 53);
    outline: none;
    border: 0;
    padding: 1vh .5vw;
    font-size: 1.3rem;
}

.sapbtptextarea {
    width: 100%;
}

.sapbtptextarea textarea::placeholder {
    color: grey;
    font-size: 1rem;
}

.sapbtpprivacypolicy {
    color: rgb(117, 117, 117);
    font-size: 1.6rem;
    width: 100%;
}

.sapbtpprivacypolicy a {
    color: #0eaae3;
}

.sapbtpsendmsgbtn {
    padding: 20px 35px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 7px;
    color: #0eaae3;
    border: 2px solid #0eaae3;
    font-size: 1.3rem;
    transition: .3s;
    margin-top: 4vh;
    background: -webkit-linear-gradient(0deg, transparent 50%, #0eaae3 50%);
    position: relative;
    background-size: 200% 100%;
    transition: .4s;
}

.sapbtpsendmsgbtn:hover {
    background-position: -100% 0;
    color: white;
}

.sapbtpselect {
    width: 100%;
}

.sapbtpselect2 {
    width: 45%;
}

.sapbtpselect select {
    padding: 18px 15px;
    background-color: #f2f2f2;
    color: rgb(53, 53, 53);
    outline: none;
    border: 0;
    font-size: 1.3rem;
}

.sapbtpsocialcontainer {
    display: flex;
    align-items: center;
    color: white;
    padding: 2vh 1vw;
    gap: 1vw;
    font-size: 1.5rem;
}

.careerformcardcontainer {
    display: flex;
    padding-left: 1vw;
}

.careerformcardlogo {
    width: 40%;
}

.careerformcardlogo i {
    font-size: 9rem;
    color: white;
}

.careerformcardinfo {
    color: white;
    padding: 1vh 1vw;
}

.careerformcardinfo label {
    font-size: 1.7rem;
    font-weight: 600;
}

.careerformcardinfo p {
    font-size: 1.4rem;
    margin-top: 1vh;
    /* font-weight: 600; */
}

.PhoneInput {
    display: flex;
    gap: 1vw;
}

.PhoneInputCountry {
    width: 2.5vw;
    display: flex;
}

.PhoneInputCountrySelect {
    width: 100%;
}

.react-tel-input .form-control {
    width: 100%;
    background-color: #f2f2f2;
    border: none;
}

.react-tel-input .form-control:focus {
    background-color: #f2f2f2;
    border-color: transparent;
    box-shadow: none;
}

@media only screen and (max-width : 600px) {
    .sapbtpappointmentmaincontainer {
        display: flex;
        flex-direction: column;
    }

    .sapbtpformsection {
        width: 100%;
    }

    .sapbtpformbody {
        width: 100%;
    }

    .sapbtpinputbox {
        width: 100%;
    }

    .sapbtpinputbox label {
        font-size: 1.1rem;
    }

    .sapbtpinputbox input {
        font-size: 1.1rem;
        padding: 10px;
    }

    .react-tel-input .form-control {
        padding: 13px;
        padding-left: 50px;
    }

    .sapbtpselect select {
        font-size: 1.1rem;
        padding: 10px;
    }

    .sapbtpinputbox textarea {
        padding: 10px;
    }

    .sapbtpprivacypolicy {
        font-size: 1rem;
    }

    .sapbtpsendmsgbtn {
        width: 100%;
        text-align: center;
        padding: 15px 25px;
        font-size: 1.1rem;

    }
}