.sap1info2maincontainer {
    width: 100vw;
    margin: 8vh 0;
}

.sap1info2childcontainer {
    width: 65vw;
    margin: auto;
}

.sap1info2mainheader {
    font-size: 2rem;
    /* font-weight: 600; */
    color: #0eaae3;
}

.sap1info2tabcontainer {
    display: flex;
    margin-top: 4vh;
    width: 100%;
    border: 10px solid #EFEFEF;
    padding: 20px;
}

.sap1info2tabdiv1 {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    justify-content: space-between;
}

.sap1info2tabhead {
    width: 15vw;
    text-align: center;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    background-color: rgb(117, 117, 117);
    color: white;
    padding: 20px 25px;
    cursor: pointer;
    transition: .3s;
}

.sap1info2tabdiv1 p:hover {
    transform: scale(1.02);
}

.sap1info2tabdiv2 {
    margin-left: 2vw;
    width: 100%;
    font-size: 1.6rem;
    color: rgb(117, 117, 117);
    display: none;
}

.tabactive {
    display: flex;
}

.headactive{
    background-color: #0eaae3;
}

@media only screen and (max-width: 600px) {
    .sap1info2tabcontainer{
        width: 80%;
        flex-direction: column;
    }

    .sap1info2tabdiv1{
        flex-direction: row;
        overflow-x: scroll;
    }

    .sap1info2tabhead{
        width: auto;
        font-size: 1.1rem;
    }

    .sap1info2tabdiv2{
        margin-top: 2vh;
        font-size: 1.1rem;
    }
}