.aboutinfolists {
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.aboutinfolist {
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.aboutinfolistheader {
    display: flex;
    align-items: center;
    /* gap: .5vw; */
}


.aboutinfolistdescription {
    padding: 0px 1vw;
    color: #595959;
    font-size: 1.4rem;
}

.aboutinfonewlist {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #e7e7e78c;
}

.aboutinfolistheader i {
    background-color: #0eaae3;
    /* width: 55px; */
    /* height: 50px; */
    color: white;
    display: flex;
    padding: 10px;
    align-items: center;
    font-size: 3.5rem;
    justify-content: center;
}

.aboutinfolistheader p {
    width: 170px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    background-color: #595959;
    color: white;
    font-size: 2.5rem;
    font-weight: bolder;
}

.aboutinfolistdescriptionboldwords {
    font-weight: 600;
}

@media only screen and (max-width : 600px) {
    .aboutinfolist {
        padding: 0vh 5vw;
    }

    .aboutinfonewlist{
        flex-direction: column;
        align-items: normal;
    }

    .aboutinfolistheader i {
        font-size: 2rem;
    }

    .aboutinfolistheader p {
        padding: 8px 10px;
        width: 100%;
        height: 36px;
        font-size: 1.1rem;
    }

    .aboutinfolistdescription {
        font-size: 1.1rem;
    }
}