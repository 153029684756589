.ednewinfo3maincontainer {
    /* width: 100vw; */
    margin: 8vh 0;
}

.ednewinfo3childcontainer {
    width: 65vw;
    margin: auto;
}

.edinfo3header {
    /* font-size: 2.5rem;
    font-weight: 600; */
    font-size: 2rem;
    font-weight: 500;
    color: #0eaae3;
    transition: .3s;
}

.edinfo3lists {
    margin-top: 4vh;
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.edinfo3list {
    transition: .3s;
    margin-top: 3vh;
    font-size: 1.6rem;
}

.edinfo3listheader {
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 1vw;
    color: rgb(117, 117, 117);
}

.edinfo3listdescription {
    color: rgb(117, 117, 117);
}

.edinfo3description {
    margin-top: 5vh;
    font-size: 1.6rem;
    color: #0eaae3;
    transition: .3s;
}

@media only screen and (max-width: 600px) {
    .ednewinfo3childcontainer{
        width: 80vw;
    }

    .edinfo3header{
        font-size: 1.5rem;
    }

    .edinfo3lists{
        gap: 2vh;
    }

    .edinfo3list{
        margin-top: 0;
        font-size: 1.1rem;
    }
}