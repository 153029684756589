.contactheromaincontainer{
    background-image: linear-gradient(180deg, #e1f5fe 25%, #ffffff 40%);
    width: 100%;
    background-color: transparent;
    padding-bottom: 20vh;
}

.contactheromediums{
    display: flex;
    justify-content: space-between;
    padding: 0vh 7vw;
}

.contactheroitem{
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.contactheroitem i{
    font-size: 2rem;
    color: #0eaae3;
}

.contactheroitem p{
    font-size: 1.6rem;
}

.contactheroitem span{
    color: #7f7f7f;
}