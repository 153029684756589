.dataaicards2maincontainer {
    width: 65vw;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    margin: 8vh auto;
}

.dataaicards2maincontainerheader {
    font-size: 2rem;
    /* font-weight: 600; */
    color: #0eaae3;
}

.dataaicards2maincontainercards {
    margin: 0 auto;
    margin-top: 3vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.dataaicards2maincontainercard {
    width: 380px;
    margin-bottom: 3vh;
    height: 130px;
    border: 1px solid #d3d3d3;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
}

.dataaicards2maincontainercard img {
    /* width: 80%; */
    height: 50%;
}

.dataaicards2maincontainercard:hover {
    transform: scale(1.02);
}


@media only screen and (max-width: 600px) {
    .scaleparent {
        width: 100%;
    }

    .dataaicards2maincontainer {
        width: 80vw;
        gap: 0;
    }

    .dataaicards2maincontainercards {
        margin-top: 4vh;
    }

    .dataaicards2maincontainercard {
        width: 100%;
        height: 10vh;
    }

    .dataaicards2maincontainercard img {
        width: 80%;
    }
}