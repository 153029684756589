.navbarmaincontainer {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    background-color: transparent;
    transition: .3s ease-in-out;
    z-index: 4;
    background-color: white;
}

.navbaractive {
    height: 8vh;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(206, 206, 206, 0.2), 0 6px 20px 0 rgba(238, 238, 238, 0.19);
}

.navbarlogosection {
    width: 10%;
    padding: 1vh 2vw;
}

.navbarlogosection img {
    width: 100%;
}

#contactusnavbaroption {
    padding: 10px 15px;
    border: 1px solid #575757;
}

#contactusnavbaroption:hover {
    background-color: #0eaae3;
    color: white;
    border: 1px solid #0eaae3;

}

.ddmenulist {
    display: flex;
    align-items: center;
    gap: 8vw;
    margin-right: 5vw;
    height: 100%;
}

.navbarcontactbtncontainer {
    display: flex;
    align-items: center;
    margin-right: 2vw;
}

.navbarcontactbtn {
    text-decoration: none;
    border: 2px solid #0eaae3;
    color: #0eaae3;
    cursor: pointer;
    padding: 15px 20px;
    background: -webkit-linear-gradient(0deg, white 50%, #0eaae3 50%);
    position: relative;
    background-size: 200% 100%;
    transition: .4s;
}

.navbarcontactbtn:hover {
    background-position: -100% 0;
    color: white;
}

.ddmenuparent {
    height: 100%;
    display: flex;
    align-items: center;
}

.ddmenuparent p::after {
    content: '';
    display: block;
    width: 0;
    position: relative;
    z-index: 6;
    bottom: -5px;
    /* height: 100%; */
    height: 2px;
    background: #0eaae3;
    transition: width .4s ease-in;
}

.ddmenuparent:hover p::after {
    width: 100%;
}

.ddmenuparent:hover .navbaroverlay {
    display: flex;
}

.ddmenuparent:hover .ddmenuchild {
    display: flex;
}

.ddmenuchild {
    display: none;
    justify-content: center;
    align-items: center;
    /* width: 23vw; */
    border-radius: 5px;
    /* height: 20vh; */
    padding: 20px;
    background-color: #fafafa;
    position: absolute;
    top: 100%;
    /* margin-top: 5vh; */
    left: 0;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 5;
}

.ddmenucontainer {
    display: flex;
    gap: 3vw;
}

.ddmenuparent {
    cursor: pointer;
    color: rgba(117, 117, 117);
    /* font-weight: 600; */
    font-size: 1.3rem;
    position: relative;
}

.ddaboutmenucontainer {
    /* gap: 8vw; */
}

.ddmenuchildsec1 {
    font-weight: 600;
    white-space: nowrap;
    font-size: 1.5rem;
}

.ddmenuchildsec2 {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    white-space: nowrap;
    /* width: 8vw; */
}

.ddmenuchildsec2itemheading {
    font-size: 1.2rem;
    font-weight: 600;
}

.ddmenuchildsec2itemheading a {
    text-decoration: none;
    transition: .3s;
    color: rgb(117, 117, 117);
}

.ddmenuchildsec2itemheading a:hover {
    color: #0eaae3;
}

.ddmenuchildsec2itemdesc {
    font-size: 1.2rem;
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
}

.ddmenuchildsec2itemdesc a {
    color: #575757;
    text-decoration: none;
    transition: .3s;
}

.ddmenuchildsec2itemdesc a:hover {
    color: #0eaae3;
}

.ddmenuchildsec2itemdesc p:hover {
    color: #0eaae3;
}

.navbaroverlay {
    width: 120vw;
    /* z-index: 4; */
    height: 100vh;
    left: -50vw;
    top: 8vh;
    display: none;
    position: absolute;
    background-color: #0000005e;
}

.ddcapabilitiesmenu {
    width: 35vw;
}

.ddcapabilitiesmenuchildsec2 {
    flex-direction: row;
    flex-wrap: wrap;
}

.ddplatformsmenu {
    width: 15vw;
}

.ddplatformsmenucontainer {
    gap: 5vw;
}

.ddcapabilitiesmenucontainer {
    /* width: 10vw; */
    justify-content: space-evenly;
}

.ddplatformsmenuchildsec2itemdesc {
    margin-top: .5vh;
}

.mobilenavbarmaincontainer{
    display: none;
}

.edqandaanswersparent{
    display: none;
    flex-direction: column;
    gap: 2vh;
    margin-top: 2vh;
}

/* For Mobile */

@media only screen and (max-width : 600px) {
    .navbarmaincontainer {
        width: 100vw;
        height: 10vh;
        display: none;
    }

    .mobilenavbarmaincontainer {
        display: flex;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        top: 0;
        background-color: white;
        width: 100vw;
        position: fixed;
        z-index: 4;
        
    }

    .mobilenavbarchildcontainer {
        width: 100%;
        height: 100%;
        padding: 2vh 0;
        display: flex;
        justify-content: space-between;
    }

    .mobilenavbarlogo {
        margin-left: 3vw;
        width: 30%;
    }

    .mobilenavbarlogo img {
        width: 100%;
    }

    .mobilenavbarbtn {
        display: flex;
        align-items: center;
        margin-right: 5vw;
    }

    .mobilenavbarbtn i {
        color: #0eaae3;
        font-size: 1.5rem;
    }

    .mobilenavbarcontentcontainer {
        width: 70vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
        background-color: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
        transition: .3s;
        transform: translateX(100vw);
    }

    .mobilenavbarcontentcontaineractive{
        transform: translateX(0);
    }

    .mobilenavbarcontentdiv1{
        width: 100%;
        padding: 20px 10px;
        border-bottom: 1px solid rgb(196, 196, 196);
    }
    .mobilenavbarcontentdiv2{
        overflow-y: scroll;
        width: 90%;
    }

    .mobilenavbarcontentlogo{
        width: 80%;
    }

    .mobilenavbarcontentlogo img{
        width: 100%;
    }

    .mobilenavbarcloseicon{
        position: absolute;
        left: -25px;
        top: 45vh;
        color: rgb(117, 117, 117);
        padding: 20px 10px;
        background-color: white;
        border-radius: 5px;
    }

    .mobilenavbarcontentdiv2{
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        height: 73%;
        justify-content: space-between;
    }    

    .navbarcontactbtncontainer{
        z-index: 5;
        margin-right: 0;
        width: 90%;
    }

    .navbarcontactbtncontainer a{
        text-align: center;
        width: 100%;
    }

    .privacypolicybodyinfo table,th,td{
        width: 90px;
    }
}