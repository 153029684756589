.dataengheromaincontainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin: 8vh 0;
    margin-top: 10vh;
}

.dataengherodiv1childcontainer {
    width: 70vw;
    margin: auto;
    display: flex;
    /* gap: 2vh; */
    flex-direction: column;
}

.dataengherodiv1 {
    width: 100%;
    background-image: url('../../../Images/Home Page/heroImage.png');
    height: 35vh;
    display: flex;
    align-items: center;
}

.dataengheromainheader {
    display: flex;
}

.dataengheromainheader p {
    font-size: 3rem;
    font-weight: 600;
    padding: 15px 0px;
    /* border: 2px solid white; */
    color: white;
}

.dataengheromainsubheader {
    width: 75%;
}

.dataengheromainsubheader p {
    color: white;
    font-size: 1.6rem;
}

.dataengherodiv2 {
    width: 65vw;
    font-size: 1.6rem;
    margin: 8vh auto;
    margin-bottom: 0vh;
    color: rgb(53, 53, 53);
    display: flex;
    align-items: center;
}

.dataengherolistheader {
    font-size: 1.6rem;
    font-weight: 600;
    color: #0eaae3;
}

.dataengherolistdescription {
    margin-top: 2vh;
    font-size: 1.6rem;
    color: rgb(117, 117, 117);
}

.dataengherolists {
    display: flex;
    flex-direction: column;
    /* gap: 4vh; */
}

.dataengheromaindesc{
    color: rgb(117, 117, 117);
}

.dataenginfomaindesc ul {
    margin-left: 1.3vw;
}

@media only screen and (max-width: 600px) {
    .dataengheromainheader p {
        font-size: 1.5rem;
    }

    .dataengheromaincontainer{
        margin-top: 9vh;
    }

    .dataengherodiv1childcontainer {
        width: 80vw;
        gap: 3vh;
    }

    .dataengheromainsubheader {
        width: 100%;
    }

    .dataengheromainsubheader p {
        font-size: 1.1rem;
    }

    .dataengherodiv2 {
        width: 80vw;
        font-size: 1.1rem;
    }

    .dataengherolists {
        gap: 1vh;
    }

    .dataengherolistheader {
        margin-top: 4vh;
    }

    .dataenginfomaindesc ul {
        margin-left: 5vw;
    }
}