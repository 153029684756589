.aimlinfomaincontainer {
    width: 100vw;
    margin: 8vh 0;
}

.aimlinfochildcontainer {
    width: 65vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.aimlinfoheader {
    font-size: 2rem;
    color: #0eaae3;
    /* font-weight: 600; */

}

.aimlinfodiv2 {
    width: 65vw;
    /* margin-top: 6vh; */
}

.aimlinfodiv2 img {
    width: 100%;
}

.aimlinfodescription{
    margin-top: 3vh;
    font-size: 1.6rem;
    color: rgb(117,117,117);
}