.ednewinfo2maincontainer {
    /* width: 100vw; */
    margin: 8vh 0;
}

.ednewinfo2childcontainer {
    width: 65vw;
    margin: auto;
}

.ednewinfo2div1 {
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.ednewinfo2div2 {
    margin: 4vh 0;
    transition: .3s;
}

.ednewinfo2div2 img {
    width: 100%;
}

.ednewinfo1header {
    /* font-size: 2.5rem;
    font-weight: 600; */
    font-size: 2rem;
    font-weight: 500;
    color: #0eaae3;
    transition: .3s;
}

.ednewinfo1description {
    font-size: 1.6rem;
    color: rgb(53, 53, 53);
    transition: .3s;
}


@media only screen and (max-width: 600px) {
    .ednewinfo2childcontainer{
        width: 80vw;
    }

    .ednewinfo2div1{
        gap: 2vh;
    }

    .ednewinfo1header{
        font-size: 1.5rem;
    }

    .ednewinfo1description{
        font-size: 1.1rem;
    }
}