.footermaincontainer{
  background-color: #00ABE9;
}

.footerchildcontainer {
  padding-top: 60px;
  width: 70vw;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footersection1 {
  width: 28vw;
  display: flex;
  flex-direction: column;
  gap: 1.5vh;
}

.footerdescription {
  color: white;
}

.footerlogo {
  width: 20vw;
}

.footerlogo img {
  width: 100%;
  height: 100%;
}

.footersocialmediaicons {
  display: flex;
  gap: 1vw;
}

.footersmicon:hover {
  background-color: #01c6fd;
  transform: scale(1.05);
}

.footersmicon {
  transition: 0.3s;
  border-radius: 50%;
  background-color: #0eaae3;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footersmicon i {
  color: white;
}

.footersection2 {
  width:60%;
  font-size: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 4vh;
}

.footersection2header {
  color: white;
}

.footersection2links {
  color: white;
}

.footersection2links {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  cursor: pointer;
}

.footersection2links a {
  font-size: 1.3rem;
  transition: .3s;
  display: flex;
  color: white;
  text-decoration: none;
}

.footersection2link {
  transition: .3s;
}

.footersection2link:hover {
  color: #0eaae3;
}

.footersection3 {
  /* width: 33vw; */
  width: 40%;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 4vh;
}

.fs3header {
  font-size: 2.5rem;
  color: #0eaae3;
}

.fs3items {
  display: flex;
  flex-direction: column;
  gap: 6vh;
}

.fs3item {
  display: flex;
}

.fs3itemsec1 i {
  font-size: 2rem;
  color: white;
  /* color: #0eaae3; */
}

.fs3itemsec2 {
  padding-left: 1vw;
}

.fs3itemsec2 p {
  font-size: 1.3rem;
  color: white;
  font-weight: 500;
}

.fs3itemsec2 span {
  font-size: 1.3rem;
  color: rgb(224, 224, 224);
}

.footersection2accordiondesc ul {
  margin-top: 1vh;
  margin-left: .3vw;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.footersection2accordiondesc ul a {
  transition: .3s;
}

.footersection2accordiondesc ul a:hover {
  color: #0eaae3;
}

.footersection2accordionheader {
  display: flex;
  justify-content: space-between;
  width: 12vw;
  transition: .3s;
}

.footersection2accordion label {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  cursor: pointer;
}

.footersection2accordion label::before {
  content: '';
  font-size: 1.5rem;
}

.footersection2accordion input[type='checkbox'] {
  display: none;
}

.footersection2accordion .footersection2accordiondesc {
  color: #595959;
  max-height: 0px;
  overflow: hidden;
  font-size: 1rem;
  transition: max-height 0.5s, padding 0.5s;
}

.footersection2accordion input[type="checkbox"]:checked+label+.footersection2accordiondesc {
  max-height: 400px;
}

.footersection2accordion input[type="checkbox"]:checked+label::before {
  content: '';
}

.footersection2linkschildcontainer {
  /* display: flex; */
  margin-bottom: 4vh;
  width: 40%;
  /* margin-right: 5vw; */
}

.footersection2linksheader {
  font-weight: 600;
  font-size: 1.3rem;
}

.footersection2linksinfo {
  margin-left: 1vw;
  margin-top: 2vh;
  font-size: .9rem;
  display: flex;
  flex-direction: column;
  gap: 2vh;
}


.footersection2links a:hover {
  color: #01c6fd;
}

.footersection4 {
  width: 100vw;
  padding: 2vh 0;
  /* border-top: rgb(197, 197, 197) 1px solid; */
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  color: white;
}

.footersection4 a{
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
  border-bottom: 1px solid white;
}

@media only screen and (max-width : 600px) {
  .footerchildcontainer{
    width: 80vw;
    flex-direction: column;
  }

  .footersection2{
    width: 100%;
  }
  
  .footersection2header{
    font-size: 1.5rem;
  }

  .footersection2links{
    justify-content: space-between;
  }

  .footersection2links a{
    font-size: 1.1rem;
  }

  .footersection2linksheader{
    font-size: 1.1rem;
  }

  .footersection2linkschildcontainer{
    margin-bottom: 4vh;
  }

  .footersection2linksinfo{
    gap: 1vh;
    margin-left: 0;
  }

  .footersection3{
    margin-top: 6vh;
    width: 100%;
  }

  .fs3header{
    font-size: 1.5rem;
    color: white;
  }

  .fs3items{
    gap: 3vh;
  }

  .fs3item{
    /* justify-content: space-between; */
    align-items: center;
  }

  .fs3itemsec1{
    margin-right: 4vw;
  }

  .fs3itemsec2 p{
    font-size: 1.1rem;
  }

  .footersection4{
    margin-top: 6vh;
    width: 100%;
    font-size: .9rem;
  }
}