.ednewinfomaincontainer {
    width: 65vw;
    /* background-color: aqua; */
    margin: 5vh auto;
    display: flex;
    /* margin-bottom: 3vh; */
}

.ednewinfodiv1 {
    width: 50%;
}

.ednewinfodiv2 {
    width: 50%;
    transition: transform .3s ease-in-out;
}

.ednewinfodiv2 img {
    width: 100%;
    transform: translateY(4vh);
    transition: transform .3s ease-in-out;
}

.ednewinfomainheader {
    margin: 5vh 0vw;
    /* font-size: 2.5rem; */
    /* font-weight: 600; */
    font-size: 2rem;
    font-weight: 500;
    color: #0eaae3;
}

.ednewinfomainlists {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 4vh;
    height: 60%;
}

.ednewinfomainlist {
    font-size: 1.6rem;
    color: rgb(53, 53, 53);
}

.ednewinfomainlist .ednewinfomainlistheader {
    font-weight: 600;
    font-size: 1.6rem;
}

.ednewinfomainlist .ednewinfomainlistdesc {
    font-size: 1.6rem;
}

.fadeparent span {
    display: block;
    transform: translateX(-100px);
    opacity: 0;
}

.slideparent span {
    display: block;
    transform: translateX(-100px);
    /* opacity: 0; */
}

.scaleparent span {
    display: block;
    /* transform: translateX(-100px); */
    scale: 1;
}

.fadeinparent span{
    display: block;
    transform: translateX(0px);
    opacity: 0;
}

@media only screen and (max-width: 600px) {
    .ednewinfomaincontainer {
        width: 80vw;
        margin: 8vh auto;
        flex-direction: column;
    }

    .ednewinfodiv1 {
        width: 100%;
    }

    .ednewinfomainheader {
        margin: 0;
        font-size: 1.5rem;
    }

    .ednewinfomainlists {
        margin-top: 4vh;
        gap: 2vh;
    }

    .ednewinfomainlist .ednewinfomainlistheader {
        font-size: 1.1rem;
    }

    .ednewinfomainlist .ednewinfomainlistdesc {
        font-size: 1.1rem;
    }

    .ednewinfodiv2 {
        width: 100%;
    }

    .slider-container {
        width: 90vw;
        margin: 8vh auto;
    }



    .slick-slider {
        margin-top: 3vh;
    }
}