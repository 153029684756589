.homeinfomaincontainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.homeinfoheader {
  font-size: 2rem;
}

.homeinfodescription {
  font-size: 1.35rem;
  width: 100%;
  /* line-break:anywhere; */
  color: #595959;
}

.homeinfosection1 {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  justify-content: space-evenly;
  width: 45%;
  padding: 15vh 4vw;
}

.homeinfosection2 {
  width: 55%;
  z-index: 99;
  justify-content: center;
  opacity: 1;
  -webkit-mask-repeat: no-repeat;
  mask-size: 100% 100%;
  /* mask-position: 5vw; */
  display: flex;
  align-items: center;
}

.homeinfosection2 img {
  width: 100%;
  height: 100%;
}

.homeinfolists {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.homeinfolist p {
  font-weight: 400;
  font-size: 1.2rem;
}

.homeinfolist i {
  color: #0eaae3;
  font-size: 1.1rem;
}

.homeinfolist {
  display: flex;
  align-items: center;
  gap: 1vw;
}

@media only screen and (max-width : 480px){
  #homeinfomaincontainer{
    flex-direction: column-reverse;
  }
  .homeinfomaincontainer{
    /* display: none; */
    flex-direction: column;
    padding-top: 3vh;
    flex-wrap: wrap;
  }

  .homeinfosection1{
    width: 100vw;
    gap: 3vh;
    padding: 4vh 0vw;
  }

  .homeinfosection2{
    width: 100vw;
    mask-size: 100% 100%;
    height: 45vh;
  }

  .homeinfosection2 img{
    height: 100%;
  }

  .homeinfolist p{
    font-size: .9rem;
  }

  .homeinfoheader{
    font-weight: 600;
    padding-left: 5vw;
    font-size: 1.7rem;
  }

  #homeinfolistsheader{
    padding-left: 5vw;
  }

  .homeinfodescription p{
    padding: 0vh 5vw;
  }

  .homeinfodescription{
    text-wrap: wrap;
    width: 100%;
    /* padding: 0vh 5vw; */
    word-wrap:break-word;
    font-size: .9rem;
  }

  .homeinfolist{
    gap: 2vw;
    padding: 0vh 5vw;
  }

  .homeinfolist i{
    font-size: .9rem;
  }  
}