.slidercard {
    border-radius: 10px;
    background-color: #01C5FD;
    color: white;
    /* height: 30vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slidercardsection1 {
    padding: 20px 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* gap: 2vw; */
}

.slidercardsection2 {
    padding: 15px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 2vw;
}

.slidercardlogo {
    /* margin-left: 20px; */
    width: 20%;
    display: flex;
    justify-content: center;
}

.slidercardheader {
    width: 60%;
    font-size: 1.8rem;
    font-weight: 600;
}

.slidercardlogo img {
    width: 100%;
    /* heig/ht: 13vh; */
}

.slidercarddesc {
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    /* gap: 2vh; */
}

.slider-buttons {
    display: flex;
    gap: 12vw;
    justify-content: center;
    color: #01C5FD;
    margin: 5vh 0;
}

.slider-buttons i {
    z-index: 3;
    font-size: 2.5rem;
    border-radius: 50%;
    border: 2px solid #01C5FD;
    cursor: pointer;
    padding: 10px 17px;
    transition: .3s;
}

.slider-buttons i:hover {
    transform: scale(1.02);
}

.slick-slide .slidercard {
    transform: scale(0.9);
    background-color: rgb(117, 117, 117);
}

.slick-slide>div {
    margin: 0 1vw;
}

.slick-current .slidercard {
    background-color: #01C5FD;
    transform: scale(1);
}

/* #decard {
    height: 68vh;
}

#s4hanacard {
    height: 66vh;
}

#qlikcard {
    height: 45vh;
}

#insightanalyticscard {
    height: 55vh;
}

#swdevcard {
    height: 50vh;
} */

.slick-dots {
    /* z-index: 4; */
    bottom: -9.5vh;
    padding-top: 50px;
}

.slider-container {
    width: 65vw;
    margin: 8vh auto;
}

.slick-slider{
    margin-top: 2vh;
}

@media only screen and (max-width: 600px) {

    .slidercard{
        height: 35vh;
    }

    .slider-container {
        width: 80vw;
    }

    .slider-buttons {
        gap: 30vw;
    }

    .slider-buttons i { 
        font-size: 2rem;
    }

    .slick-dots {
        bottom: -4vh;
    }

    .slidercardheader {
        font-size: 1.1rem;
    }

    .slidercarddesc {
        font-size: 1.1rem;
    }

    #decard{
        height: 72vh;
    }

    #insightanalyticscard{
        height: 65vh;
    }

    #s4hanacard{
        height: 79vh;
    }

    #qlikcard{
        height: 50vh;
    }
}