.homepagesection3maincontainer {
    width: 65vw;
    margin: 12vh auto;
}

.homepagesection3maindescription {
    margin-top: 2vh;
}

.homepagesection3mainheader p {
    font-size: 2.5rem;
    /* font-weight: 600; */
    color: #0eaae3;
}

.homepagesection3maindescription p {
    color: #595959;
    font-size: 1.5rem;
    font-weight: 600;
}

.homesection3cards {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    gap: 15vh;
}

.homesection3cardheader {
    font-size: 3rem;
    display: flex;
    align-items: center;
    color: #0eaae3;
}

.homesection3cardheader p {
    margin-left: 1vw;
    font-size: 2rem;
    font-weight: 600;
    color: #595959;
}

.homesection3carddescription {
    margin-top: 4vh;
}

.homesection3carddescription p {
    font-size: 1.35rem;
    color: #595959;
}

.homesection3cardsubcards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4vh;
}

.homesection3cardsubcard {
    border: 1px solid rgb(167, 167, 167);
    border-radius: 5px;
    width: 45%;
    padding: 20px;
    margin-bottom: 3vh;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.homesection3cardsubcard2 {
    color: black;
    text-decoration: none;
    border: 1px solid rgb(167, 167, 167);
    border-radius: 5px;
    width: 45%;
    padding: 20px;
    margin-bottom: 3vh;
    cursor: pointer;
}

.homesection3cardsubcard:hover .homesection3cardsubcardheader p:after {
    width: 100%;
}

.homesection3cardsubcardheader {
    display: flex;
}

.homesection3cardsubcardheader p {
    font-size: 2rem;
}

.homesection3cardsubcardheader p:after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #0eaae3;
    transition: width .4s ease-in;
}

.homesection3cardsubcarddescription {
    margin-top: 6vh;
}

.homesection3cardsubcarddescription p {
    font-size: 1.35rem;
    color: #595959;
}

@media only screen and (max-width: 600px) {

    .homepagesection3maincontainer{
        margin: 0 auto;
        width: 80vw;
    }

    .homepagesection3mainheader p {
        font-size: 1.8rem;
    }

    .homepagesection3maindescription p {
        font-size: 1.3rem;
    }

    .homepagesection2whowearebtn{
        font-size: 1rem;    
    }

    .homesection3cardheader p {
        font-size: 1.3rem;
        margin-left: 4vw;
    }

    .homesection3carddescription {
        margin-top: 2vh;
    }

    .homesection3carddescription p {
        font-size: 1.1rem;
    }

    .homesection3cardsubcards {
        margin-top: 4vh;
    }

    .homesection3cardsubcardheader p {
        font-size: 1.3rem;
        font-weight: 600;
        color: rgb(117, 117, 117);
    }

    .homesection3cardsubcarddescription {
        margin-top: 4vh;
    }
    
    .homesection3cardsubcarddescription p {
        font-size: 1.1rem;
    }

    .homesection3cards{
        gap: 12vh;
    }
    
    .homesection3cardsubcard2,.homesection3cardsubcard{
        width: 100%;
    }

}