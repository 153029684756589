.accordion li {
    list-style: none;
    width: 100%;
    border-radius: 8px;
}


.accordion li label {
    display: flex;
    align-items: center;
    /* padding: 0px 10px; */
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
}

.accordion li label::before {
    content: '+';
    font-weight: 600;
    margin-right: 10px;
    font-size: 3rem;
}

.accordion input[type='checkbox'] {
    display: none;
}

.accordion .content {
    color: #595959;
    padding: 0 10px;
    /* line-height: 1.3rem; */
    margin-left: 2vw;
    line-break: anywhere;
    max-height: 0;
    overflow: hidden;
    font-size: 1.6rem;
    transition: max-height 0.5s, padding 0.5s;
}

.accordion input[type="checkbox"]:checked+label+.content {
    max-height: 400px;
}

.insidelist {
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.insidelist li {
    list-style-type: circle;
    display: flex;
    align-items: center;
    align-items: center;
}

.insidelist li i {
    margin-right: .5vw;
}

.accordion input[type="checkbox"]:checked+label::before {
    content: '-';
}