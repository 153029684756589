.privacypolicybodymaincontainer {
    padding-top: 13vh;
    width: 100vw;
}

.cookiepolicybodymainheader p {
    padding: 0vh 20vw;
}

.cookiepolicybodymainheader {
    width: 100vw;
    color: #0eaae3;
    margin: 5vh auto;
    font-size: 3rem;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
}

.privacypolicybodymainheader {
    width: 100vw;
    color: #ffffff;
    /* background-color: #0eaae3; */
    background-image: url('../../Images/Home Page/heroImage.png');
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 4vh;
}

.privacypolicybodymainheader p {
    padding: 10vh 20vw;
    padding-top: 14vh;
    padding-bottom: 15vh;
}

.privacypolicybodyinfo {
    width: 60vw;
    font-size: 1.6rem;
    margin: 0 auto;
    color: rgb(117, 117, 117);
}

.privacypolicybodyinfo table,
th,
td {
    border: 1px solid rgb(117, 117, 117
    );
    border-collapse: collapse;
}

.privacypolicybodyinfo td {
    /* font-size: 1rem; */
    /* text-align: center; */
    padding: 0 25px ;
}

.privacypolicybodyinfo th {
    font-size: 1.4rem;
    color: #0eaae3;
    background-color: #F2F2F2;
}

.privacypolicybodyinfo ul {
    padding-left: 2vw;
}

.privacypolicybodyinfo span {
    font-size: 2rem;
    color: #0eaae3;
    /* font-weight: 600; */
}

@media only screen and (max-width : 800px) {

    .privacypolicybodymainheader {
        font-size: 2rem;
        width: 100vw;
    }
    
    .privacypolicybodymainheader p{
        padding: 30vw 10vw;
    }
    
    .cookiepolicybodymainheader{
        font-size: 1.8rem;
        margin-bottom: 3vh;
    }
    
    .cookiepolicybodymainheader p{
        padding: 0vw 5vw;
    }

    .privacypolicybodyinfo span{
        font-size: 1.6rem;
    }
    
    .privacypolicybodyinfo {
        font-size: 1.3rem;
        width: 100vw;
        padding: 0 5vw;
    }
}