.cookieconsentparentcontainer{
    width: 100vw;
    position: fixed;
    bottom: -2vh;
    z-index: 999;
    display: none;
    justify-content:flex-end;
}

.cookieconsentmaincontainer{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0vh 2vw;
    right: 20px;
    gap: 2vh;
    margin: 10px 0;
    box-shadow: 0 4px 8px 0 rgba(139, 139, 139, 0.2), 0 6px 20px 0 rgba(143, 143, 143, 0.19);
    background-color: white;
    border-radius: 5px;
}

.cookieconsentsec1{
    align-items:  center;
    font-size: 2rem;
    gap: .5vw;
    display: flex;
    padding: 0vh 20px;
    padding-top: 1.5vh;
    color: #0eaae3;
}

.cookieconsentsec2{
    padding: 0vh 20px;
}

.cookieconsentsec2 p a{
    text-decoration: none;
    color: #0eaae3;
}

.cookieconsentsec3{
    display: flex;
    /* justify-content: space-between; */
    gap: 20px;
    padding: 0vh 20px;
    padding-bottom: 2vh;
}

.cookieconsentacceptbtn{
    font-size: 1.1rem;
    padding: 10px 30px;
    background-color: #0eaae3;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
}

.cookieconsentdeclinebtn{
    font-size: 1.1rem;
    border: 1px solid #0eaae3;
    border-radius: 5px;
    padding: 10px 30px;
    color: #0eaae3;
    cursor: pointer;
    transition: .3s;
}

.cookieconsentacceptbtn:hover{
    background-color: #0c99cc;
}

.cookieconsentdeclinebtn:hover{
    background-color: #def6ff;
}

@media only screen and (max-width : 800px) {
    .cookieconsentmaincontainer{
        width: 320px;
        right: auto;
        margin: 0 auto;
    }

    .cookieconsentsec3{
        gap: 0;
        justify-content: space-between;
    }
}